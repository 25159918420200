import React, { FunctionComponent as FC, useContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getFromStorage } from '../util/storage-utils';
import {
  renderPageComponents,
  getStatusAlertComponent,
  getHeroComponent,
  getSplitHeaderComponent,
} from '../util/contentful-render-utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ContentfulElementColumns2 > div': {
        padding: theme.spacing(3, 1),
      },
      '& .ContentfulModuleAccordion > div': {
        padding: theme.spacing(3),
      },
    },
    spacing: {
      padding: theme.spacing(2, 0),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 1.75),
      },
    },
    header: {
      '& .hero-container': {
        minHeight: 320,
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
          minHeight: 'auto',
        },
      },
    },
  }),
);

const StormInfo: FC = () => {
  const { page } = useStaticQuery(graphql`
    query stormCenterContent {
      page: allContentfulPage2Column(
        filter: { contentful_id: { eq: "4P7eDOG0b97gBrfzNLh1ud" } }
      ) {
        nodes {
          node_locale
          contentful_id
          entryName
          shortMenuTitle
          slug
          pageTitle
          pageSubtitle
          image {
            file {
              contentType
              fileName
              url
            }
          }
          contentEntries {
            ...ModuleContentBody
            ...ModuleMessagingBlock
            ...ModuleSplitHeader
            ...ModuleHeroHeader
          }
          columnA {
            ...ModuleContentBody
            ...ModuleNote
            ...ModuleTabSet
            ...ModuleCallOut
            ...ModuleColumn2
            ...ModuleColumn3
            ...ModuleCallToAction
            ...ModuleMessagingBlock
            ...ModuleAccordion
            ...ModulePromoGroup
            ...ModuleImageWrapper
            ...ModuleEmbedMediaWrapper
          }
          columnB {
            ...ModuleContentBody
            ...ModuleCallOut
            ...ModuleImageWrapper
            ...ModuleEmbedMediaWrapper
          }
          browserTitle
          metaDescriptionSeo
          statusAlert {
            ...ModuleStatusAlert
          }
          excludeFromGoogleSearch
          excludeFromXmlSiteMap
        }
      }
    }
  `);

  const { language } = useContext(LanguageContext);
  const [content] = page.nodes.filter(
    (pg: any) => pg.node_locale === (language || 'en'),
  );

  const classes = useStyles();

  const contentBodyHeaderType =
    content?.contentEntries && content?.contentEntries[0]?.__typename;
  let header;
  if (
    contentBodyHeaderType === 'ContentfulHeaderSplit' ||
    contentBodyHeaderType === 'ContentfulHeaderhero'
  ) {
    header =
      contentBodyHeaderType === 'ContentfulHeaderhero'
        ? getHeroComponent([content?.contentEntries[0]])
        : getSplitHeaderComponent(content?.contentEntries[0]);
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{content?.browserTitle || content?.pageTitle}</title>
        {content?.metaDescriptionSeo && (
          <meta name="description" content={content.metaDescriptionSeo} />
        )}
      </Helmet>
      {header && <div className={classes.header}>{header}</div>}
      <Container className={classes.root}>
        <Grid container>
          {getStatusAlertComponent(content?.statusAlert)}
          {content?.contentEntries && content?.contentEntries.length > 0 && (
            <Grid item xs={12}>
              {renderPageComponents({
                entriesData: content?.contentEntries,
              })}
            </Grid>
          )}
          <Grid item xs={12} sm={8} className={classes.spacing}>
            {renderPageComponents({
              entriesData: content?.columnA,
            })}
          </Grid>
          <Grid item xs={12} sm={4} className={classes.spacing}>
            {renderPageComponents({
              entriesData: content?.columnB,
            })}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default StormInfo;
